import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SupplierCategoryComponent } from "./supplier-category/supplier-category.component";
//import { SupplierCategoryModule } from "./supplier.module";
import { SupplierAddComponent } from "./supplier-add/supplier-add.component";
import { EditComponent } from "./edit/edit.component";







const routes: Routes = [
  {
    path: "",
    component: SupplierCategoryComponent
  },
  {
    path: "add",
    component: SupplierAddComponent
  },
  {
    path: "edit",
    component: EditComponent
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierCategoryRoutingModule {}
