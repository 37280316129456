import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  project: any = {};
  states: string[] = ['State 1', 'State 2', 'State 3'];
  categoryForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  keyword = 'productName'
  product:string[]=[]
  isEnabled = false;
  unitName:any;
  isDisabled = false;
  productName:any[]=[];
  id:any;
  constructor(private data: DataService,
    private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {
    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }
    this.categoryForm= this.formBuilder.group({
      productName: ["",],
      shortName:[""],
     
     
    });
  }

 

  goBack() {
    this.router.navigate(['/dashboard/supplierCategory']);
  }
  get f() {
    return this.categoryForm.controls;
  }
  onSubmit(data: any) {
    if (this.categoryForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn = moment().format();
    //data.isActive = true
    
    this.data.update(data,this.id).subscribe((res:any)=>{
     console.log(res)
      this.toastr.showSuccess("Successfully!!","Supplier Category Added")
      this.router.navigate(['/dashboard/supplierCategory'])
    })
  }
  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.categoryForm.patchValue({
    productName:data.productName,
    shortName:data.shortName
    })
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
    this.unitName = item.unitName
   
    this.categoryForm.patchValue({
      productName:item.productName,
      shortName:item.shortName,
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getProduct().subscribe((res:any)=>{
      this.productName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.productName)
      return this.productName.filter(val => val.toLowerCase().includes(search))

    })
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }

  enabled(){
    console.log(this.isEnabled)
    if(this.isEnabled === true){
    this.categoryForm.patchValue({
      cstatus:true
    })
  } else{
    this.categoryForm.patchValue({
      cstatus:false
    })
  }
  }
}
