<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Supplier Category</h2>
    <form [formGroup]="categoryForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="productName">Name<span class="requried-field">*</span></label>
          <input
            type="text"
             id="productName"
            class="form-control"
            formControlName="shortName"
            [ngClass]="{ 'is-invalid': submitted && f['productName'].errors }"
          />
        <label for="productName">{{this.unitName}}</label>
          <div *ngIf="submitted && f['productName'].errors" class="invalid-feedback">
            <div *ngIf="f['productName'].errors['required']">Name is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="shortName">Short Name<span class="requried-field">*</span></label>
          <input
            type="text"
             id="shortName"
            class="form-control"
            formControlName="shortName"
            [ngClass]="{ 'is-invalid': submitted && f['shortName'].errors }"
          />
          <div *ngIf="submitted && f['shortName'].errors" class="invalid-feedback">
            <div *ngIf="f['shortName'].errors['required']">Short Name is required</div>
          </div>
        </div>
        <!-- <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="supplierName">Supplier Name<span class="requried-field">*</span></label>
          <input
            type="text"
           
           
            class="form-control"
            formControlName="supplierName"
            [ngClass]="{ 'is-invalid': submitted && f['supplierName'].errors }"
          />
          <div *ngIf="submitted && f['supplierName'].errors" class="invalid-feedback">
            <div *ngIf="f['supplierName'].errors['required']">Supplier Name is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="pstatus">Status<span class="requried-field">*</span></label>
          <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
         
        </div> -->
      </div>
      <button type="submit" class="btn btn-primary" (click)="onSubmit(categoryForm.value)">Submit</button>
      </form>
      </div>