import { Component } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';
import moment from 'moment';

@Component({
  selector: 'app-supplier-add',
  templateUrl: './supplier-add.component.html',
  styleUrl: './supplier-add.component.scss'
})
export class SupplierAddComponent {
  project: any = {};
  states: string[] = ['State 1', 'State 2', 'State 3'];
  categoryForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  keyword = 'productName'
  product:string[]=[]
  isEnabled = false;
  unitName:any;
  isDisabled = false;
  productName:any[]=[];
  constructor(private data: DataService,
    private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router
  ) {}

  ngOnInit()  {
    this.categoryForm= this.formBuilder.group({
      productName: ["",],
      shortName:[""],
     
     
    });
  }

 

  goBack() {
    this.router.navigate(['/dashboard/supplierCategory']);
  }
  get f() {
    return this.categoryForm.controls;
  }
  onSubmit(data: any) {
    if (this.categoryForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn = moment().format();
    data.isActive = true
    
    this.data.create(data).subscribe((res:any)=>{
     console.log(res)
      this.toastr.showSuccess("Successfully!!","Supplier Category Added")
      this.router.navigate(['/dashboard/supplierCategory'])
    })
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
    this.unitName = item.unitName
   
    this.categoryForm.patchValue({
      productName:item.productName,
      shortName:item.shortName,
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getProduct().subscribe((res:any)=>{
      this.productName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.productName)
      return this.productName.filter(val => val.toLowerCase().includes(search))

    })
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }

  enabled(){
    console.log(this.isEnabled)
    if(this.isEnabled === true){
    this.categoryForm.patchValue({
      cstatus:true
    })
  } else{
    this.categoryForm.patchValue({
      cstatus:false
    })
  }
  }
}
