import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
//import { ToastrModule } from 'ngx-toastr';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatCardModule} from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';

import { NotificationService } from '../../services/notificationService';
import { NgxPaginationModule } from 'ngx-pagination';
import { SupplierCategoryRoutingModule } from './supplier.routing';
import { SupplierAddComponent } from './supplier-add/supplier-add.component';
import { SupplierCategoryComponent } from './supplier-category/supplier-category.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { EditComponent } from './edit/edit.component';


@NgModule({
    declarations: [
       SupplierAddComponent,
       SupplierCategoryComponent,
       EditComponent,
    ],
    providers: [NotificationService],
    imports: [

       
        CommonModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        FormsModule,
        NgxPaginationModule,
        ReactiveFormsModule,
       // ToastrModule.forRoot(),
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatCardModule,
       // MatPaginator,
        MatPaginatorModule,
        //MatTableDataSource,
        MatProgressBarModule,
        MatGridListModule,
        SupplierCategoryRoutingModule,
        AutocompleteLibModule,
        MatSliderModule,
        MatSlideToggleModule
        
        
       
        
    ]
})
export class SupplierCategoryModule{ }
